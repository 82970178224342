import Link from "next/link";
import dynamic from "next/dynamic";
import { fetcher } from "./api/util/fetcher";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useApp } from "@/components/context/AppContext/AppContext";
import { useAuth } from "@/components/auth/hooks/useAuth";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { useAuthUser, withAuthUser } from "next-firebase-auth";
import { isUserAuthed } from "@/common/utils/userRoles";
//BOOTSTRAP
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
// COMPONENTS
import Carousel from "@/components/core/LadingPage/Carousel/Carousel";
const CarouselCategories = dynamic(() => import("@/components/core/LadingPage/Carousel/CarouselCategories"), { ssr: false });
import Banner from "@/components/core/LadingPage/Banner/Banner";
import BootstrapSpinner from "@/components/elements/LoadingPlaceholders/BootstrapSpinner";
import Hero from "@/components/core/LadingPage/Hero/Hero";
import VerticalPhotoBanner from "@/components/core/LadingPage/VerticalPhotoBanner/VerticalPhotoBanner";


const bannerColumns0 = [
  {
    id: 0,
    title: "landing-page.discover-presales",
  },
];
const bannerLinks0 = [{ id: 1, text: "landing-page.shop-now", href: "/presales/ALL_PRODUCTS" }];

const bannerColumns1 = [
  {
    id: 1,
    title: "landing-page.discover-latest-arrivals",
  },
];
const bannerLinks1 = [{ id: 1, text: "landing-page.new-arrivals", href: "/catalogue/ALL_PRODUCTS?orderBy=LATEST-ARRIVALS" }];

const bannerColumns2 = [
  {
    id: 1,
    title: "landing-page.discover-trendy-items",
  },
];
const bannerLinks2 = [{ id: 1, text: "landing-page.best-sellers", href: "/catalogue/ALL_PRODUCTS?orderBy=RECOMMENDED" }];

const bannerColumns3 = [
  {
    id: 1,
    title: "landing-page.discover-discounted-items",
  },
];
const bannerLinks3 = [{ id: 1, text: "landing-page.discounted-items", href: "/catalogue/ALL_PRODUCTS?orderBy=DISCOUNTED" }];

const LandingPage = (props) => {
  const AuthUser = useAuthUser();
  const { t } = useTranslation(["common", "about-us"]);
  const auth = useAuth();
  const app = useApp();
  const router = useRouter();
  const [clothingProductsGroup, setclothingProductsGroup] = useState([]);

  // Set colors and sizes from app context, check if values are ready.
  useEffect(() => {
    if (app.appConfig?.clothingProductsGroup) {
      setclothingProductsGroup(app.appConfig?.clothingProductsGroup);
    }
  }, [app]);

  // If the user is not allowed will be redirect to homepage.
  useEffect(() => {
    if (auth.isUserSupplier()) {
      router.push("/home-supplier");
    }
  }, [auth, router]);

  return auth.isUserSupplier() ? (
    <BootstrapSpinner />
  ) : (
    <>
      <Container fluid="md" className="justify-content-center">
        {/* <div className="d-none d-lg-flex" style={{ height: "3rem" }}></div> */}
        {app.appConfig?.showPresales?.customer && auth.isUserAllowPresale() &&
          <div className="mx-0 py-3">
            <Banner color="banner-blue" columns={bannerColumns0} links={bannerLinks0} buttonVariant="outline-light" signupModal={false} t={t} />
          </div>
        }
        {/* <div className="mx-0 py-3">
          <Banner color="banner-green" columns={bannerColumns1} links={bannerLinks1} buttonVariant="outline-light" signupModal={false} t={t} />
        </div> */}
        <div className="mx-0 py-3">
        <Hero
            buttonHref={"/catalogue/ALL_PRODUCTS?season=FW2024"}
            buttonText={t("landing-page.shop-now")}
            title={t("landing-page.new-collection")}
            text={t("landing-page.season-fw") + " 2024"}
            text_footer={''}
          />
          </div>
        <div className="mx-0 py-3">
          <Carousel
            autoplay={false}
            title={t(`landing-page.new-arrivals`)}
            products={props.latestProducts.products}
            slides={6}
            titleStyle={"fw-semibold fs-5 pb-2"}
          />
        </div>
        {/* <div className="mx-0 py-3">
          <Banner color="banner-green" columns={bannerColumns1} links={bannerLinks1} buttonVariant="outline-light" signupModal={false} t={t} />
        </div> */}
        <div className="mx-0 py-3">
          <VerticalPhotoBanner title={true} subtitle={false} button={"BOTTONE"} />
        </div>
        {isUserAuthed(AuthUser) && (
          <>
            <div className="mx-0 py-3">
              <Carousel
                autoplay={false}
                title={t(`landing-page.trending-now`)}
                products={props.recommendedProducts.products?.slice(0, 12)}
                slides={6}
                titleStyle={"fw-semibold fs-5 pb-2"}
              />
            </div>
            <div className="mx-0 py-3">
              <Banner color="banner-orange" columns={bannerColumns2} links={bannerLinks2} buttonVariant="outline-light" signupModal={false} t={t} />
            </div>
          </>
        )}
        <div className="mx-0 py-3">
          <CarouselCategories title={t("landing-page.all-categories")} />
        </div>
        {props.discountedProducts.count > 0 && (
          <>
            <div className="mx-0 py-3">
              <Carousel
                autoplay={false}
                title={t(`landing-page.discounted-items`)}
                products={props.discountedProducts.products}
                slides={6}
                titleStyle={"fw-semibold fs-5 pb-2"}
              />
            </div>
            <div className="mx-0 py-3">
              <Banner color="banner-blue" columns={bannerColumns3} links={bannerLinks3} buttonVariant="outline-light" signupModal={false} t={t} />
            </div>
          </>
        )}
        <div className="px-3 pt-5 pb-4">
          <h1 className="fs-4 mb-4 fw-semibold">{t(`landing-page.home-title`)}</h1>

          <p>{t("subtitle1", { ns: "about-us" })}</p>
          <p>{t("subtitle2", { ns: "about-us" })}</p>
          <p>{t("subtitle3", { ns: "about-us" })}</p>

          <div className="d-grid justify-content-center d-lg-flex gap-2 py-4">
            <Link href="/catalogue/ALL_PRODUCTS" passHref>
              <a>
                <Button variant="dark" className="fw-semibold" size="lg">
                  {t("landing-page.browse-catalog").toUpperCase()}
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
};

export async function getStaticProps({ locale }) {
  const filterLatestProducts: any = { limit: 12, orderBy: "NEW", stato: 1 };
  const latestProducts = await fetcher("/api/product/catalogue" + "?" + new URLSearchParams(filterLatestProducts).toString());

  const filterRecommendedProducts: any = { limit: 12, orderBy: "RECOMMENDED", stato: 1 };
  const recommendedProducts = await fetcher("/api/product/catalogue" + "?" + new URLSearchParams(filterRecommendedProducts).toString());

  const filterDiscountedProducts: any = { limit: 12, orderBy: "DISCOUNTED", stato: 1 };
  const discountedProducts = await fetcher("/api/product/catalogue" + "?" + new URLSearchParams(filterDiscountedProducts).toString());

  return {
    props: {
      latestProducts,
      recommendedProducts,
      discountedProducts,
      ...(await serverSideTranslations(locale, ["about-us", "common", "header", "footer", "db-translations"])),
    },
  };
}

export default withAuthUser({})(LandingPage);
