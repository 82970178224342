import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import LinkButton from "@/components/elements/LinkButton/LinkButton";
// BOOTSTRAP
import Card from "react-bootstrap/Card";
// STYLE / IMAGES
import s from "./Hero.module.scss";
import image from "@/public/static/images/hero_autumn.webp";
import { useState, useEffect } from "react";

interface HeroProps {
  buttonHref: string;
  buttonHref2?: string;
  buttonText: string;
  buttonText2?: string;
  title: string;
  text: string;
  text_footer: string;
}
export default function Hero({ buttonHref, buttonHref2, buttonText, buttonText2, title, text, text_footer }: HeroProps) {
  const { t } = useTranslation("common");

  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <Link href={buttonHref} passHref>
      <div>
        <Card style={{ cursor: "pointer", border: "none", position: "relative", height: "32rem" }} className="mobile:h-10">
          <Image layout="fill" objectFit="cover" alt={"hero image"} width={150} height={150} src={image} className={s.image} priority={true} sizes="100vw" />
          <Card.ImgOverlay className="p-0">
            <div
              className={`${s.text_wrapper} align-items-center 
            text-center`}
            >
              <div className="h-20 d-grid align-items-center">
                <div className="py-2">
                  <h1 style={{ fontSize: "3rem", color: "#FFFFFF", fontWeight: "bold" }}>{title}</h1>
                </div>
                <div className="py-2">
                  <h4 className="text-light">{text}</h4>
                </div>
                <div className="py-2">
                  <h6 className="text-light">{text_footer}</h6>
                </div>

                <div className="py-2 ">
                  <section className="d-flex flex-column gap-2 align-items-center">
                    <LinkButton href={buttonHref} buttonStyle="fw-bold text-dark border border-1 border-dark bg-light py-2 px-3 w-50">
                      <span className="text-uppercase">{t(buttonText)}</span>
                    </LinkButton>
                    {buttonHref2 && (
                      <LinkButton href={buttonHref2} buttonStyle="fw-bold text-dark border border-1 border-dark bg-light py-2 px-3 w-50">
                        <span className="text-uppercase">{t(buttonText2)}</span>
                      </LinkButton>
                    )}
                  </section>
                </div>
              </div>
            </div>
          </Card.ImgOverlay>
        </Card>
      </div>
    </Link>
  );
}
